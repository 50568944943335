.border-none[data-v-9a9937c2]:before {
  display: none;
}
.border-none[data-v-9a9937c2]:after {
  display: none;
}
.flex-fix[data-v-9a9937c2] {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  flex: 1;
  -ms-flex-preferred-size: 0px;
      flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar[data-v-9a9937c2]::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover[data-v-9a9937c2] {
  position: absolute;
  top: 0;
  right: 0;
}
.center[data-v-9a9937c2] {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ct[data-v-9a9937c2] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.cl[data-v-9a9937c2] {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.clear-fix[data-v-9a9937c2] {
  *zoom: 1;
}
.clear-fix[data-v-9a9937c2]:before,
.clear-fix[data-v-9a9937c2]:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
#announcement .announcement-cell[data-v-9a9937c2] {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 0.76rem;
  padding-left: 1.76rem;
}
#announcement .announcement-cell .icon[data-v-9a9937c2] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 0.3rem;
  width: 1.16rem;
  height: 0.38rem;
  background-color: var(--main-color);
  z-index: 1;
}
#announcement .announcement-content[data-v-9a9937c2] {
  width: 100%;
  font-size: 0.26rem;
  color: #666;
  line-height: 0.32rem;
  letter-spacing: 0.01rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#announcement[data-v-9a9937c2] .yd-rollnotice {
  background-color: transparent;
}
