.border-none[data-v-b6a6a9fe]:before {
  display: none;
}
.border-none[data-v-b6a6a9fe]:after {
  display: none;
}
.flex-fix[data-v-b6a6a9fe] {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  flex: 1;
  -ms-flex-preferred-size: 0px;
      flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar[data-v-b6a6a9fe]::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover[data-v-b6a6a9fe] {
  position: absolute;
  top: 0;
  right: 0;
}
.center[data-v-b6a6a9fe] {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ct[data-v-b6a6a9fe] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.cl[data-v-b6a6a9fe] {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.clear-fix[data-v-b6a6a9fe] {
  *zoom: 1;
}
.clear-fix[data-v-b6a6a9fe]:before,
.clear-fix[data-v-b6a6a9fe]:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.mall[data-v-b6a6a9fe] {
  min-height: 100vh;
  margin-bottom: 1.3rem;
}
.mall .my-points[data-v-b6a6a9fe] {
  height: 0.8rem;
  margin: 0 0.3rem;
  background-image: url('../../assets/icon_topic_back.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border-radius: 0.64rem;
  color: #ffffff;
  font-size: 14px;
}
.mall .my-points .points-com[data-v-b6a6a9fe] {
  float: left;
  height: 0.8rem;
  line-height: 0.8rem;
  margin-left: 0.2rem;
}
.mall .my-points .space[data-v-b6a6a9fe] {
  letter-spacing: 0.02rem;
}
.mall .my-points img[data-v-b6a6a9fe] {
  width: 0.48rem;
  height: 0.48rem;
  margin: 0.16rem 0 0 0.12rem;
}
.mall .item-list[data-v-b6a6a9fe] {
  margin: 0 0.3rem;
}
