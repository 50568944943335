.border-none:before {
  display: none;
}
.border-none:after {
  display: none;
}
.flex-fix {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  -webkit-flex-basis: 0px;
  flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover {
  position: absolute;
  top: 0;
  right: 0;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ct {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.cl {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.clear-fix {
  *zoom: 1;
}
.clear-fix:before,
.clear-fix:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.page {
  background-color: #fff;
}
.page .horizontal-scroll-wrap {
  position: relative;
  width: 100%;
  height: 1.08rem;
  background: linear-gradient(227deg, #ff4444 0%, #fc3e6c 100%);
  overflow: hidden;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.page .horizontal-scroll-wrap .tab-list {
  display: flex;
  justify-content: flex-start;
  height: 100%;
}
.page .horizontal-scroll-wrap .tab-list li {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1.5rem;
  height: 100%;
  text-align: center;
  white-space: nowrap;
  padding: 0 0.4rem;
}
.page .horizontal-scroll-wrap .tab-list li .time {
  font-size: 0.36rem;
  color: #fff;
  line-height: 0.5rem;
}
.page .horizontal-scroll-wrap .tab-list li .status {
  font-size: 0.22rem;
  color: #fff;
  line-height: 0.32rem;
}
.page .horizontal-scroll-wrap .tab-list li.active .time {
  font-size: 0.38rem;
  color: #fff;
  line-height: 0.52rem;
  font-weight: bolder;
}
.page .horizontal-scroll-wrap .tab-list li.active .status {
  font-weight: bold;
}
.page /deep/ .time-clock {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 0.6rem;
  padding-left: 0.24rem;
  padding-right: 0.24rem;
  background-color: #ffeceb;
}
.page /deep/ .time-clock .tip {
  font-size: 0.26rem;
  color: #fd4060;
  line-height: 0.36rem;
  word-wrap: break-word;
  word-break: break-all;
}
.page /deep/ .time-clock .countdown-wrap {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0.24rem;
  display: flex;
  align-items: center;
  z-index: 2;
}
.page /deep/ .time-clock .countdown-wrap .txt {
  margin-right: 0.12rem;
  font-size: 0.24rem;
  color: #fd4060;
  line-height: 0.34rem;
}
.page /deep/ .time-clock .countdown-wrap .time {
  display: inline-block;
  width: 0.32rem;
  height: 0.32rem;
  margin-top: 0.1rem;
  font-size: 0.22rem;
  color: #fff;
  line-height: 0.32rem;
  text-align: center;
  background: linear-gradient(227deg, #ff4444 0%, #fc3e6c 100%);
  border-radius: 2px;
  overflow: hidden;
}
.page /deep/ .time-clock .countdown-wrap .colon {
  display: inline-block;
  width: 0.12rem;
  height: 0.32rem;
  font-size: 0.22rem;
  color: #ff7f00;
  transform: translateY(-25%);
  text-align: center;
  line-height: 0.32rem;
}
.page /deep/ .item-list-wrap /deep/ .row-item:first-child {
  margin-top: 0.3rem;
}
