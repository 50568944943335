.border-none[data-v-070cf218]:before {
  display: none;
}
.border-none[data-v-070cf218]:after {
  display: none;
}
.flex-fix[data-v-070cf218] {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  flex: 1;
  -ms-flex-preferred-size: 0px;
      flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar[data-v-070cf218]::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover[data-v-070cf218] {
  position: absolute;
  top: 0;
  right: 0;
}
.center[data-v-070cf218] {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ct[data-v-070cf218] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.cl[data-v-070cf218] {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.clear-fix[data-v-070cf218] {
  *zoom: 1;
}
.clear-fix[data-v-070cf218]:before,
.clear-fix[data-v-070cf218]:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
@-webkit-keyframes opacity-in-070cf218 {
0% {
    opacity: 0;
}
to {
    opacity: 1;
}
}
@keyframes opacity-in-070cf218 {
0% {
    opacity: 0;
}
to {
    opacity: 1;
}
}
#banner[data-v-070cf218] {
  position: relative;
  width: 100%;
  height: 4.28rem;
  padding: 0.16rem 0.311rem 0.34rem;
  z-index: 1;
}
#banner .img-box[data-v-070cf218] {
  width: 100%;
  height: 3.3rem;
  padding: 0 5px;
}
#banner .img-box img[data-v-070cf218] {
  width: 100%;
  height: 100%;
}
#banner .pagination[data-v-070cf218] {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  bottom: 0.34rem;
  width: 100%;
  z-index: 2;
  pointer-events: none;
  opacity: 0;
  -webkit-animation: opacity-in-070cf218 0.3s linear forwards;
          animation: opacity-in-070cf218 0.3s linear forwards;
}
#banner .pagination .pagination-item[data-v-070cf218] {
  display: inline-block;
  width: 0.1rem;
  height: 0.1rem;
  margin: 0.05rem;
  border-radius: 0.05rem;
  background-color: #e1e1e1;
}
#banner .pagination .pagination-item.pagination-item-active[data-v-070cf218] {
  width: 0.24rem;
  background-color: #333;
}
#banner .shadow[data-v-070cf218] {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  bottom: 0.34rem;
  width: 100%;
  height: 1.14rem;
  background-image: url('../../assets/img_home_banner_shadow@2x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
@media (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 3) {
#banner .shadow[data-v-070cf218] {
    background-image: url('../../assets/img_home_banner_shadow@3x.png');
}
}
