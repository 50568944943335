.border-none:before {
  display: none;
}
.border-none:after {
  display: none;
}
.flex-fix {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  -webkit-flex-basis: 0px;
  flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover {
  position: absolute;
  top: 0;
  right: 0;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ct {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.cl {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.clear-fix {
  *zoom: 1;
}
.clear-fix:before,
.clear-fix:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
@keyframes opacity-in {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
#banner {
  position: relative;
  width: 100%;
  height: 4.28rem;
  padding: 0.16rem 0.311rem 0.34rem;
  z-index: 1;
}
#banner .img-box {
  width: 100%;
  height: 3.3rem;
  padding: 0 5px;
}
#banner .img-box img {
  width: 100%;
  height: 100%;
}
#banner .pagination {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0.34rem;
  width: 100%;
  z-index: 2;
  pointer-events: none;
  opacity: 0;
  animation: opacity-in 0.3s linear forwards;
}
#banner .pagination .pagination-item {
  display: inline-block;
  width: 0.1rem;
  height: 0.1rem;
  margin: 0.05rem;
  border-radius: 0.05rem;
  background-color: #e1e1e1;
}
#banner .pagination .pagination-item.pagination-item-active {
  width: 0.24rem;
  background-color: #333;
}
#banner .shadow {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0.34rem;
  width: 100%;
  height: 1.14rem;
  background-image: url('../../assets/img_home_banner_shadow@2x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
@media (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 3) {
  #banner .shadow {
    background-image: url('../../assets/img_home_banner_shadow@3x.png');
  }
}
