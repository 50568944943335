.border-none[data-v-057ed981]:before {
  display: none;
}
.border-none[data-v-057ed981]:after {
  display: none;
}
.flex-fix[data-v-057ed981] {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  flex: 1;
  -ms-flex-preferred-size: 0px;
      flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar[data-v-057ed981]::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover[data-v-057ed981] {
  position: absolute;
  top: 0;
  right: 0;
}
.center[data-v-057ed981] {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ct[data-v-057ed981] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.cl[data-v-057ed981] {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.clear-fix[data-v-057ed981] {
  *zoom: 1;
}
.clear-fix[data-v-057ed981]:before,
.clear-fix[data-v-057ed981]:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
#coupon .img-box[data-v-057ed981] {
  width: 100%;
  margin-top: 3px;
}
#coupon .img-box img[data-v-057ed981] {
  width: 96%;
  margin-left: 2%;
}
