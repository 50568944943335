.border-none:before {
  display: none;
}
.border-none:after {
  display: none;
}
.flex-fix {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  -webkit-flex-basis: 0px;
  flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover {
  position: absolute;
  top: 0;
  right: 0;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ct {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.cl {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.clear-fix {
  *zoom: 1;
}
.clear-fix:before,
.clear-fix:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
#new-category {
  padding-bottom: 0.2rem;
}
#new-category .four {
  margin: 0 0.3rem;
}
#new-category .four .category-item {
  width: 25%;
  margin-bottom: 0.3rem;
}
#new-category .four .category-item .img-box {
  height: 1rem;
  margin-bottom: 0.2rem;
}
#new-category .four .category-item .txt-box {
  height: 0.36rem;
}
#new-category .four .category-icon {
  width: 1rem;
  height: 1rem;
}
#new-category .four .category-txt {
  font-size: 0.26rem;
  color: #666;
  line-height: 0.36rem;
}
#new-category .five {
  margin: 0 0.4rem;
}
#new-category .five .category-item {
  width: 20%;
  margin-bottom: 0.11rem;
}
#new-category .five .category-item .icon-style {
  padding: 0.25rem;
}
#new-category .five .category-item .txt-box {
  color: #333333;
  font-size: 0.2rem;
}
#new-category .category-list {
  position: relative;
  overflow: hidden;
}
#new-category .category-list .category-item {
  float: left;
  position: relative;
  z-index: 0;
}
#new-category .border-four {
  border-radius: 0.2rem;
  background-color: #ffffff;
  padding-top: 0.3rem;
  box-shadow: 0 0 5px #e0e0e0;
  -webkit-box-shadow: 0 0 5px #e0e0e0;
}
#new-category .border-five {
  border-radius: 0.2rem;
  background-color: #ffffff;
  padding: 0.1rem 0 0.3rem 0;
  box-shadow: 0 0 5px #e0e0e0;
  -webkit-box-shadow: 0 0 5px #e0e0e0;
}
