.border-none:before {
  display: none;
}
.border-none:after {
  display: none;
}
.flex-fix {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  -webkit-flex-basis: 0px;
  flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover {
  position: absolute;
  top: 0;
  right: 0;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ct {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.cl {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.clear-fix {
  *zoom: 1;
}
.clear-fix:before,
.clear-fix:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.page {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #FAFAFA;
  overflow: hidden;
  overflow-y: auto;
}
.page .info-box {
  padding: 0 0.3rem;
  background-color: #fff;
}
.page .info-box .title {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 1rem;
  border-bottom: 1px solid #F1F1F1;
}
.page .info-box .title .txt {
  font-size: 0.28rem;
  color: #999;
  line-height: 0.4rem;
}
.page .info-box .title .time {
  font-size: 0.24rem;
  color: #999;
  line-height: 0.34rem;
  text-align: right;
}
.page .info-box .content {
  padding: 0.2rem 0;
  font-size: 0.28rem;
  color: #333;
  line-height: 0.5rem;
  font-weight: bold;
  word-wrap: break-word;
  word-break: break-all;
}
.page .info-box .content .no-title {
  text-align: center;
  font-size: 0.32rem;
  color: #333;
}
