.border-none[data-v-1807769c]:before {
  display: none;
}
.border-none[data-v-1807769c]:after {
  display: none;
}
.flex-fix[data-v-1807769c] {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  flex: 1;
  -ms-flex-preferred-size: 0px;
      flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar[data-v-1807769c]::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover[data-v-1807769c] {
  position: absolute;
  top: 0;
  right: 0;
}
.center[data-v-1807769c] {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ct[data-v-1807769c] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.cl[data-v-1807769c] {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.clear-fix[data-v-1807769c] {
  *zoom: 1;
}
.clear-fix[data-v-1807769c]:before,
.clear-fix[data-v-1807769c]:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.white[data-v-1807769c] {
  background-color: #fff;
}
.page[data-v-1807769c] {
  height: 100%;
}
.page .search-blank[data-v-1807769c] {
  width: 100%;
  height: 1rem;
  padding: 0 0.3rem;
  background-color: #fff;
}
.page .search-history-wrap[data-v-1807769c] {
  border-top: 0.2rem solid #F5F5F5;
}
.page .search-history-wrap .title[data-v-1807769c] {
  padding: 0.2rem 0.3rem;
  font-size: 0.24rem;
  color: #333;
  line-height: 0.34rem;
  font-weight: 500;
}
.page .search-history-wrap .search-history[data-v-1807769c] {
  padding-left: 0.3rem;
}
.page .search-history-wrap .search-history li[data-v-1807769c] {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 0.22rem 0;
  border-bottom: 1px solid #ededee;
  overflow: hidden;
  z-index: 1;
}
.page .search-history-wrap .search-history li .icon-time[data-v-1807769c] {
  display: inline-block;
  width: 0.2rem;
  height: 0.2rem;
  margin-right: 0.2rem;
  background-image: url('../../assets/ic_time@2x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
@media (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 3) {
.page .search-history-wrap .search-history li .icon-time[data-v-1807769c] {
    background-image: url('../../assets/ic_time@3x.png');
}
}
.page .search-history-wrap .search-history li .txt[data-v-1807769c] {
  font-size: 0.28rem;
  color: #333;
  line-height: 0.32rem;
  word-wrap: break-word;
  word-break: break-all;
}
.page .search-history-wrap .search-history li .del-btn[data-v-1807769c] {
  position: absolute;
  top: 0;
  right: 0;
  width: 0.96rem;
  height: 0.8rem;
  z-index: 2;
}
.page .search-history-wrap .search-history li .del-btn .icon-delete[data-v-1807769c] {
  width: 0.36rem;
  height: 0.36rem;
  background-image: url('../../assets/del@2x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
@media (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 3) {
.page .search-history-wrap .search-history li .del-btn .icon-delete[data-v-1807769c] {
    background-image: url('../../assets/del@3x.png');
}
}
.page .search-history-wrap .clear-history[data-v-1807769c] {
  width: 2rem;
  height: 0.6rem;
  margin: 0.78rem auto 0;
  font-size: 0.24rem;
  color: #666;
  line-height: 0.6rem;
  text-align: center;
  white-space: nowrap;
  background-color: #F5F5F5;
  border-radius: 0.08rem;
  overflow: hidden;
}
.page .search-none[data-v-1807769c] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-top: 0.2rem solid #F5F5F5;
}
.page .search-none .icon-search-none[data-v-1807769c] {
  width: 2.2rem;
  height: 1.8rem;
  margin: 2.34rem auto 0.6rem;
  background-image: url('../../assets/icon_serch_kong@2x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
@media (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 3) {
.page .search-none .icon-search-none[data-v-1807769c] {
    background-image: url('../../assets/icon_serch_kong@3x.png');
}
}
.page .search-none .tip[data-v-1807769c] {
  margin-bottom: 0.2rem;
  font-size: 0.28rem;
  color: #909090;
  line-height: 0.4rem;
  text-align: center;
}
.page .search-none .go-btn[data-v-1807769c] {
  width: 2.8rem;
  height: 0.68rem;
  font-size: 0.32rem;
  color: #fff;
  line-height: 0.68rem;
  text-align: center;
  white-space: nowrap;
  background-color: var(--main-color);
  border-radius: 0.44rem;
  overflow: hidden;
}
.page .search-none .go-btn[data-v-1807769c]:active {
  color: #fff;
  background-color: rgba(16, 143, 244, 0.7);
}
.page[data-v-1807769c] .search-result-wrap {
  margin-bottom: 0.06rem;
}
.page[data-v-1807769c] .search-result-wrap .price-search {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 0.3rem 0.06rem;
  background-color: #fff;
}
.page[data-v-1807769c] .search-result-wrap .price-search .price-input {
  width: 2.8rem;
  height: 0.64rem;
  border-radius: 0.08rem;
  border: 1px solid #e1e1e1;
  overflow: hidden;
}
.page[data-v-1807769c] .search-result-wrap .price-search .price-input input {
  width: 100%;
  height: 100%;
  padding: 0.1rem 0.4rem;
  font-size: 0.28rem;
  color: #333;
  line-height: 0.44rem;
}
.page[data-v-1807769c] .search-result-wrap .price-search .price-input input:disabled {
  outline: none;
  border: none;
}
.page[data-v-1807769c] .search-result-wrap .price-search .price-input input:active {
  outline: var(--main-color);
}
.page[data-v-1807769c] .search-result-wrap .price-search .line {
  display: inline-block;
  width: 0.2rem;
  height: 0.02rem;
  margin: 0 0.08rem;
  background-color: var(--main-color);
}
.page[data-v-1807769c] .search-result-wrap .price-search .search-btn {
  margin-left: 0.3rem;
  font-size: 0.28rem;
  color: #666;
  line-height: 0.4rem;
}
.page[data-v-1807769c] .search-result-wrap .search-tab {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  height: 0.88rem;
  padding-right: 1.2rem;
  overflow: hidden;
  background-color: #fff;
  -webkit-box-shadow: 0px 0.06rem 0.4rem rgba(0, 0, 0, 0.05);
          box-shadow: 0px 0.06rem 0.4rem rgba(0, 0, 0, 0.05);
}
.page[data-v-1807769c] .search-result-wrap .search-tab .tab-list {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.page[data-v-1807769c] .search-result-wrap .search-tab .tab-list li {
  width: 30%;
  float: left;
  text-align: center;
  position: relative;
  z-index: 0;
}
.page[data-v-1807769c] .search-result-wrap .search-tab .tab-list li .txt {
  position: relative;
  font-size: 0.28rem;
  color: #999;
  line-height: 0.4rem;
  letter-spacing: 0.01rem;
}
.page[data-v-1807769c] .search-result-wrap .search-tab .tab-list li .icon-price {
  display: inline-block;
  width: 0.18rem;
  height: 0.2rem;
  margin-left: 0.04rem;
  background-image: url('../../assets/icon_jiage@2x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
@media (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 3) {
.page[data-v-1807769c] .search-result-wrap .search-tab .tab-list li .icon-price {
    background-image: url('../../assets/icon_jiage@3x.png');
}
}
.page[data-v-1807769c] .search-result-wrap .search-tab .tab-list li.active .txt {
  color: #333;
  font-weight: 500;
}
.page[data-v-1807769c] .search-result-wrap .search-tab .tab-list li.active .txt:after {
  content: "";
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  bottom: -0.04rem;
  width: 0.5rem;
  height: 0.04rem;
  background-color: var(--main-color);
  border-radius: 0.06rem;
}
.page[data-v-1807769c] .search-result-wrap .search-tab .toggle-btn {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 0;
  width: 1.2rem;
  height: 0.88rem;
  z-index: 2;
}
.page[data-v-1807769c] .search-result-wrap .search-tab .toggle-btn .icon-col {
  width: 0.4rem;
  height: 0.4rem;
  background-image: url('../../assets/icon_sousuo_qiehuan@2x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
@media (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 3) {
.page[data-v-1807769c] .search-result-wrap .search-tab .toggle-btn .icon-col {
    background-image: url('../../assets/icon_sousuo_qiehuan@3x.png');
}
}
.page[data-v-1807769c] .search-result-wrap .search-tab .toggle-btn .icon-col.row {
  background-image: url('../../assets/ic_list_qiehuan@2x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
@media (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 3) {
.page[data-v-1807769c] .search-result-wrap .search-tab .toggle-btn .icon-col.row {
    background-image: url('../../assets/ic_list_qiehuan@3x.png');
}
}
.page[data-v-1807769c] .search-result-wrap .search-result {
  padding: 0.1rem 0.3rem;
  background-color: #F5F5F5;
}
