.border-none[data-v-5c7a5452]:before {
  display: none;
}
.border-none[data-v-5c7a5452]:after {
  display: none;
}
.flex-fix[data-v-5c7a5452] {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  flex: 1;
  -ms-flex-preferred-size: 0px;
      flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar[data-v-5c7a5452]::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover[data-v-5c7a5452] {
  position: absolute;
  top: 0;
  right: 0;
}
.center[data-v-5c7a5452] {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ct[data-v-5c7a5452] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.cl[data-v-5c7a5452] {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.clear-fix[data-v-5c7a5452] {
  *zoom: 1;
}
.clear-fix[data-v-5c7a5452]:before,
.clear-fix[data-v-5c7a5452]:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
#prefecture[data-v-5c7a5452] {
  padding: 0.2rem 0.32rem 0.1rem;
}
#prefecture .prefecture-list[data-v-5c7a5452] {
  position: relative;
  overflow: hidden;
}
#prefecture .prefecture-list .prefecture-item[data-v-5c7a5452] {
  width: 48.7%;
  margin-bottom: 0.16rem;
  float: left;
  position: relative;
  z-index: 0;
}
#prefecture .prefecture-list .prefecture-item .img-box[data-v-5c7a5452] {
  width: 100%;
  height: 1.36rem;
}
#prefecture .prefecture-list .prefecture-item .img-box img[data-v-5c7a5452] {
  width: 100%;
  height: 100%;
}
#prefecture .prefecture-list .prefecture-item[data-v-5c7a5452]:nth-child(odd) {
  margin-right: 2.4%;
}
#prefecture .nav-type[data-v-5c7a5452] {
  overflow: hidden;
  width: 100%;
}
#prefecture .nav-type .box[data-v-5c7a5452] {
  width: 48.7%;
  float: left;
}
#prefecture .nav-type .box[data-v-5c7a5452]:first-child {
  margin-right: 2.2%;
}
#prefecture .nav-type .box img[data-v-5c7a5452] {
  width: 100%;
  height: auto;
}
#prefecture .nav-type .box .up[data-v-5c7a5452] {
  margin-bottom: 2.2%;
}
