@import "../../assets/iconfont/iconfont.css";
.border-none:before {
  display: none;
}
.border-none:after {
  display: none;
}
.flex-fix {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  -webkit-flex-basis: 0px;
  flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover {
  position: absolute;
  top: 0;
  right: 0;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ct {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.cl {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.clear-fix {
  *zoom: 1;
}
.clear-fix:before,
.clear-fix:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.nav-container {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 0.98rem;
  background: #fff;
  box-shadow: 0 -1px 0.2rem 0 rgba(0, 0, 0, 0.1);
  z-index: 999;
}
.nav-container .nav-item {
  flex: 1;
  flex-direction: column;
  height: 100%;
}
.nav-container .nav-item .main-color {
  display: inline-block;
  margin-bottom: 0.04rem;
}
.nav-container .nav-item .icon {
  width: 0.48rem;
  height: 0.48rem;
  font-size: 0.48rem;
  color: #999;
  margin-bottom: 0.08rem;
}
.nav-container .nav-item .nav-txt {
  font-size: 0.22rem;
  color: #999;
  line-height: 0.32rem;
}
.nav-container .nav-item .yi_ic_home_blue_,
.nav-container .nav-item .yi_ic_type_blue_,
.nav-container .nav-item .yi_ic_shopping_blue_,
.nav-container .nav-item .yi_ic_my_blue_ {
  color: var(--main-color);
}
.nav-container .nav-item.router-link-active .nav-txt {
  color: var(--main-color);
}
.nav-container .nav-item .grey {
  color: #b3b3b3;
}
