.border-none:before {
  display: none;
}
.border-none:after {
  display: none;
}
.flex-fix {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  -webkit-flex-basis: 0px;
  flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover {
  position: absolute;
  top: 0;
  right: 0;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ct {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.cl {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.clear-fix {
  *zoom: 1;
}
.clear-fix:before,
.clear-fix:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.mall {
  min-height: 100vh;
  margin-bottom: 1.3rem;
}
.mall .my-points {
  height: 0.8rem;
  margin: 0 0.3rem;
  background-image: url('../../assets/icon_topic_back.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border-radius: 0.64rem;
  color: #ffffff;
  font-size: 14px;
}
.mall .my-points .points-com {
  float: left;
  height: 0.8rem;
  line-height: 0.8rem;
  margin-left: 0.2rem;
}
.mall .my-points .space {
  letter-spacing: 0.02rem;
}
.mall .my-points img {
  width: 0.48rem;
  height: 0.48rem;
  margin: 0.16rem 0 0 0.12rem;
}
.mall .item-list {
  margin: 0 0.3rem;
}
