.border-none[data-v-da392372]:before {
  display: none;
}
.border-none[data-v-da392372]:after {
  display: none;
}
.flex-fix[data-v-da392372] {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  flex: 1;
  -ms-flex-preferred-size: 0px;
      flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar[data-v-da392372]::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover[data-v-da392372] {
  position: absolute;
  top: 0;
  right: 0;
}
.center[data-v-da392372] {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ct[data-v-da392372] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.cl[data-v-da392372] {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.clear-fix[data-v-da392372] {
  *zoom: 1;
}
.clear-fix[data-v-da392372]:before,
.clear-fix[data-v-da392372]:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.page[data-v-da392372] {
  background-color: #fff;
}
.page .horizontal-scroll-wrap[data-v-da392372] {
  position: relative;
  width: 100%;
  height: 1.08rem;
  background: linear-gradient(227deg, #ff4444 0%, #fc3e6c 100%);
  overflow: hidden;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.page .horizontal-scroll-wrap .tab-list[data-v-da392372] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  height: 100%;
}
.page .horizontal-scroll-wrap .tab-list li[data-v-da392372] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 1.5rem;
  height: 100%;
  text-align: center;
  white-space: nowrap;
  padding: 0 0.4rem;
}
.page .horizontal-scroll-wrap .tab-list li .time[data-v-da392372] {
  font-size: 0.36rem;
  color: #fff;
  line-height: 0.5rem;
}
.page .horizontal-scroll-wrap .tab-list li .status[data-v-da392372] {
  font-size: 0.22rem;
  color: #fff;
  line-height: 0.32rem;
}
.page .horizontal-scroll-wrap .tab-list li.active .time[data-v-da392372] {
  font-size: 0.38rem;
  color: #fff;
  line-height: 0.52rem;
  font-weight: bolder;
}
.page .horizontal-scroll-wrap .tab-list li.active .status[data-v-da392372] {
  font-weight: bold;
}
.page[data-v-da392372] .time-clock {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  height: 0.6rem;
  padding-left: 0.24rem;
  padding-right: 0.24rem;
  background-color: #ffeceb;
}
.page[data-v-da392372] .time-clock .tip {
  font-size: 0.26rem;
  color: #fd4060;
  line-height: 0.36rem;
  word-wrap: break-word;
  word-break: break-all;
}
.page[data-v-da392372] .time-clock .countdown-wrap {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 0.24rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  z-index: 2;
}
.page[data-v-da392372] .time-clock .countdown-wrap .txt {
  margin-right: 0.12rem;
  font-size: 0.24rem;
  color: #fd4060;
  line-height: 0.34rem;
}
.page[data-v-da392372] .time-clock .countdown-wrap .time {
  display: inline-block;
  width: 0.32rem;
  height: 0.32rem;
  margin-top: 0.1rem;
  font-size: 0.22rem;
  color: #fff;
  line-height: 0.32rem;
  text-align: center;
  background: linear-gradient(227deg, #ff4444 0%, #fc3e6c 100%);
  border-radius: 2px;
  overflow: hidden;
}
.page[data-v-da392372] .time-clock .countdown-wrap .colon {
  display: inline-block;
  width: 0.12rem;
  height: 0.32rem;
  font-size: 0.22rem;
  color: #ff7f00;
  -webkit-transform: translateY(-25%);
          transform: translateY(-25%);
  text-align: center;
  line-height: 0.32rem;
}
.page[data-v-da392372] .item-list-wrap /deep/ .row-item:first-child {
  margin-top: 0.3rem;
}
