.border-none[data-v-1ba5b56c]:before {
  display: none;
}
.border-none[data-v-1ba5b56c]:after {
  display: none;
}
.flex-fix[data-v-1ba5b56c] {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  flex: 1;
  -ms-flex-preferred-size: 0px;
      flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar[data-v-1ba5b56c]::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover[data-v-1ba5b56c] {
  position: absolute;
  top: 0;
  right: 0;
}
.center[data-v-1ba5b56c] {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ct[data-v-1ba5b56c] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.cl[data-v-1ba5b56c] {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.clear-fix[data-v-1ba5b56c] {
  *zoom: 1;
}
.clear-fix[data-v-1ba5b56c]:before,
.clear-fix[data-v-1ba5b56c]:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.page[data-v-1ba5b56c] {
  padding: 0.3rem;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #fff;
  overflow: hidden;
  overflow-y: auto;
}
.page[data-v-1ba5b56c] .announcement-list {
  width: 100%;
}
.page[data-v-1ba5b56c] .announcement-list li {
  position: relative;
  width: 100%;
  height: 1.34rem;
  padding: 0.2rem 0.6rem 0.2rem 0.2rem;
  background-color: #fff;
  -webkit-box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.05);
          box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.05);
  border-radius: 0.08rem;
  overflow: hidden;
}
.page[data-v-1ba5b56c] .announcement-list li .title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.page[data-v-1ba5b56c] .announcement-list li .title .left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.page[data-v-1ba5b56c] .announcement-list li .title .left .txt {
  font-size: 0.28rem;
  color: #999;
  line-height: 0.4rem;
}
.page[data-v-1ba5b56c] .announcement-list li .title .left .red-dot {
  width: 6px;
  height: 6px;
  margin-left: 0.1rem;
  background-color: #ff1344;
  border-radius: 50%;
  overflow: hidden;
}
.page[data-v-1ba5b56c] .announcement-list li .title .time {
  font-size: 0.24rem;
  color: #999;
  line-height: 0.34rem;
  text-align: right;
}
.page[data-v-1ba5b56c] .announcement-list li .content {
  max-width: 100%;
  margin-top: 0.1rem;
  font-size: 0.32rem;
  color: #333;
  line-height: 0.44rem;
  font-weight: bold;
  word-wrap: break-word;
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.page[data-v-1ba5b56c] .announcement-list li .icon-detail {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 0.16rem;
  display: inline-block;
  width: 0.24rem;
  height: 0.24rem;
  background-image: url('../../../assets/Rectangle@2x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  z-index: 2;
}
@media (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 3) {
.page[data-v-1ba5b56c] .announcement-list li .icon-detail {
    background-image: url('../../../assets/Rectangle@3x.png');
}
}
.page[data-v-1ba5b56c] .announcement-list li + li {
  margin-top: 0.3rem;
}
