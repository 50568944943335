.border-none:before {
  display: none;
}
.border-none:after {
  display: none;
}
.flex-fix {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  -webkit-flex-basis: 0px;
  flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover {
  position: absolute;
  top: 0;
  right: 0;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ct {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.cl {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.clear-fix {
  *zoom: 1;
}
.clear-fix:before,
.clear-fix:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.page /deep/ .search-result-wrap .search-tab {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 0.88rem;
  padding-right: 1.2rem;
  background-color: #fff;
  overflow: hidden;
}
.page /deep/ .search-result-wrap .search-tab .tab-list {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.page /deep/ .search-result-wrap .search-tab .tab-list li {
  width: 30%;
  float: left;
  text-align: center;
  position: relative;
  z-index: 0;
}
.page /deep/ .search-result-wrap .search-tab .tab-list li .txt {
  position: relative;
  font-size: 0.28rem;
  color: #999;
  line-height: 0.4rem;
  letter-spacing: 0.01rem;
}
.page /deep/ .search-result-wrap .search-tab .tab-list li .icon-price {
  display: inline-block;
  width: 0.18rem;
  height: 0.2rem;
  margin-left: 0.04rem;
  background-image: url('../../assets/icon_jiage@2x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
@media (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 3) {
  .page /deep/ .search-result-wrap .search-tab .tab-list li .icon-price {
    background-image: url('../../assets/icon_jiage@3x.png');
  }
}
.page /deep/ .search-result-wrap .search-tab .tab-list li.active .txt {
  color: #333;
  font-weight: 500;
}
.page /deep/ .search-result-wrap .search-tab .tab-list li.active .txt:after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -0.04rem;
  width: 0.5rem;
  height: 0.04rem;
  background-color: var(--main-color);
  border-radius: 0.06rem;
}
.page /deep/ .search-result-wrap .search-tab .toggle-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: 1.2rem;
  height: 0.88rem;
  z-index: 2;
}
.page /deep/ .search-result-wrap .search-tab .toggle-btn .icon-col {
  width: 0.4rem;
  height: 0.4rem;
  background-image: url('../../assets/icon_sousuo_qiehuan@2x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
@media (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 3) {
  .page /deep/ .search-result-wrap .search-tab .toggle-btn .icon-col {
    background-image: url('../../assets/icon_sousuo_qiehuan@3x.png');
  }
}
.page /deep/ .search-result-wrap .search-tab .toggle-btn .icon-col.row {
  background-image: url('../../assets/ic_list_qiehuan@2x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
@media (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 3) {
  .page /deep/ .search-result-wrap .search-tab .toggle-btn .icon-col.row {
    background-image: url('../../assets/ic_list_qiehuan@3x.png');
  }
}
.page /deep/ .search-result-wrap .search-result {
  padding: 0 0.3rem 0.3rem 0.3rem;
  background-color: #F5F5F5;
}
.page /deep/ .search-result-wrap .search-result /deep/ .row-item-list {
  padding-top: 0.3rem;
}
