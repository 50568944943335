.border-none[data-v-15f18461]:before {
  display: none;
}
.border-none[data-v-15f18461]:after {
  display: none;
}
.flex-fix[data-v-15f18461] {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  flex: 1;
  -ms-flex-preferred-size: 0px;
      flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar[data-v-15f18461]::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover[data-v-15f18461] {
  position: absolute;
  top: 0;
  right: 0;
}
.center[data-v-15f18461] {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ct[data-v-15f18461] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.cl[data-v-15f18461] {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.clear-fix[data-v-15f18461] {
  *zoom: 1;
}
.clear-fix[data-v-15f18461]:before,
.clear-fix[data-v-15f18461]:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.page[data-v-15f18461] {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #fff;
  overflow: hidden;
  overflow-y: auto;
}
.page[data-v-15f18461] .comment-list {
  padding: 0.3rem;
}
.page[data-v-15f18461] .comment-list .comment-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}
.page[data-v-15f18461] .comment-list .comment-item .headimg-box {
  width: 0.7rem;
  height: 0.7rem;
  margin-right: 0.14rem;
  border-radius: 50%;
  overflow: hidden;
}
.page[data-v-15f18461] .comment-list .comment-item .headimg-box img {
  width: 100%;
  height: 100%;
}
.page[data-v-15f18461] .comment-list .comment-item .headimg-right {
  width: calc(100% - 0.84rem);
}
.page[data-v-15f18461] .comment-list .comment-item .headimg-right .title {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.page[data-v-15f18461] .comment-list .comment-item .headimg-right .title .nickname {
  max-width: 60%;
  font-size: 0.28rem;
  color: #333;
  line-height: 0.4rem;
  word-wrap: break-word;
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.page[data-v-15f18461] .comment-list .comment-item .headimg-right .title .rate-wrap {
  text-align: right;
}
.page[data-v-15f18461] .comment-list .comment-item .headimg-right .title .rate-wrap .time {
  margin-right: 0.05rem;
  font-size: 0.24rem;
  color: #999;
  line-height: 0.34rem;
  white-space: nowrap;
}
.page[data-v-15f18461] .comment-list .comment-item .headimg-right .comment-txt {
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  font-size: 0.28rem;
  color: #333;
  line-height: 0.4rem;
  word-wrap: break-word;
  word-break: break-all;
}
.page[data-v-15f18461] .comment-list .comment-item .headimg-right .comment-pic-wrap {
  width: 100%;
}
.page[data-v-15f18461] .comment-list .comment-item .headimg-right .comment-pic-wrap /deep/ .img-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.page[data-v-15f18461] .comment-list .comment-item .headimg-right .comment-pic-wrap /deep/ .img-list img {
  width: 1.9rem;
  height: 1.9rem;
}
.page[data-v-15f18461] .comment-list .comment-item .headimg-right .comment-pic-wrap /deep/ .img-list img + img {
  margin-left: 0.24rem;
}
.page[data-v-15f18461] .comment-list .comment-item + .comment-item {
  margin-top: 0.3rem;
}
