.border-none[data-v-a4f957ac]:before {
  display: none;
}
.border-none[data-v-a4f957ac]:after {
  display: none;
}
.flex-fix[data-v-a4f957ac] {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  flex: 1;
  -ms-flex-preferred-size: 0px;
      flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar[data-v-a4f957ac]::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover[data-v-a4f957ac] {
  position: absolute;
  top: 0;
  right: 0;
}
.center[data-v-a4f957ac] {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ct[data-v-a4f957ac] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.cl[data-v-a4f957ac] {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.clear-fix[data-v-a4f957ac] {
  *zoom: 1;
}
.clear-fix[data-v-a4f957ac]:before,
.clear-fix[data-v-a4f957ac]:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
#group-buy[data-v-a4f957ac] {
  padding: 0.4rem 0.3rem 0.2rem 0.3rem;
}
#group-buy .title[data-v-a4f957ac] {
  font-size: 0.4rem;
  color: #333;
  line-height: 0.56rem;
  font-weight: bold;
}
#group-buy .cell[data-v-a4f957ac] {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-right: 0.24rem;
}
#group-buy .cell .left .brief[data-v-a4f957ac] {
  font-size: 0.32rem;
  color: #999;
  line-height: 0.44rem;
}
#group-buy .cell .right[data-v-a4f957ac] {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
#group-buy .cell .right .check-btn[data-v-a4f957ac] {
  font-size: 0.28rem;
  color: #999;
  line-height: 0.4rem;
}
#group-buy .cell .icon-arrow-right[data-v-a4f957ac] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 0;
  display: inline-block;
  width: 0.12rem;
  height: 0.21rem;
  background-image: url('../../assets/icpn_gengduo@2x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
@media (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 3) {
#group-buy .cell .icon-arrow-right[data-v-a4f957ac] {
    background-image: url('../../assets/icpn_gengduo@3x.png');
}
}
#group-buy .item-list[data-v-a4f957ac] {
  position: relative;
  overflow: hidden;
}
#group-buy .item-list .item[data-v-a4f957ac] {
  width: 3.35rem;
  height: 4.85rem;
  margin-top: 0.2rem;
  float: left;
  position: relative;
  background: #fff;
  -webkit-box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.05);
          box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.05);
  border-radius: 0.08rem;
  overflow: hidden;
  z-index: 0;
}
#group-buy .item-list .item .img-box[data-v-a4f957ac] {
  width: 100%;
  height: 3.35rem;
  margin-bottom: 0.14rem;
}
#group-buy .item-list .item .img-box .img[data-v-a4f957ac] {
  width: 100%;
  height: 100%;
}
#group-buy .item-list .item[data-v-a4f957ac]:nth-child(odd) {
  margin-right: 0.2rem;
}
#group-buy .item-list .item .item-name[data-v-a4f957ac] {
  padding: 0 0.2rem;
  margin-bottom: 0.12rem;
  font-size: 0.26rem;
  color: rgba(0, 0, 0, 0.87);
  line-height: 0.36rem;
  display: -webkit-box;
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  word-wrap: break-word;
}
#group-buy .item-list .item .item-price[data-v-a4f957ac] {
  padding-left: 0.2rem;
}
#group-buy .item-list .item .item-price .price[data-v-a4f957ac] {
  color: #333;
  line-height: 0.28rem;
  font-weight: bold;
}
#group-buy .item-list .item .item-price .price .sign[data-v-a4f957ac] {
  font-size: 0.24rem;
}
#group-buy .item-list .item .item-price .price .num[data-v-a4f957ac] {
  font-size: 0.32rem;
}
#group-buy .item-list .item .buy-btn[data-v-a4f957ac] {
  position: absolute;
  right: 0;
  bottom: 0.2rem;
  width: 0.9rem;
  height: 0.48rem;
  padding-left: 0.26rem;
  padding-top: 0.08rem;
  font-size: 0.24rem;
  color: #fff;
  line-height: 0.34rem;
  font-weight: 500;
  white-space: nowrap;
  background-color: var(--main-color);
  border-radius: 2rem 0px 0px 2rem;
  z-index: 2;
}
#group-buy .item-list .item .group-num-wrap[data-v-a4f957ac] {
  position: absolute;
  top: 0.2rem;
  left: 0.2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  z-index: 2;
}
#group-buy .item-list .item .group-num-wrap .group-num[data-v-a4f957ac] {
  font-size: 0.22rem;
  color: #fff;
  line-height: 0.32rem;
  white-space: nowrap;
}
