.border-none[data-v-8b29be28]:before {
  display: none;
}
.border-none[data-v-8b29be28]:after {
  display: none;
}
.flex-fix[data-v-8b29be28] {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  flex: 1;
  -ms-flex-preferred-size: 0px;
      flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar[data-v-8b29be28]::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover[data-v-8b29be28] {
  position: absolute;
  top: 0;
  right: 0;
}
.center[data-v-8b29be28] {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ct[data-v-8b29be28] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.cl[data-v-8b29be28] {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.clear-fix[data-v-8b29be28] {
  *zoom: 1;
}
.clear-fix[data-v-8b29be28]:before,
.clear-fix[data-v-8b29be28]:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.page[data-v-8b29be28] {
  background-image: url('#fff@2x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
@media (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 3) {
.page[data-v-8b29be28] {
    background-image: url('#fff@3x.png');
}
}
.page[data-v-8b29be28] .item-list-wrap {
  padding-left: 0.3rem;
  padding-right: 0.3rem;
}
.page[data-v-8b29be28] .item-list-wrap /deep/ .row-item:first-child {
  margin-top: 0.3rem;
}
