.border-none:before {
  display: none;
}
.border-none:after {
  display: none;
}
.flex-fix {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  -webkit-flex-basis: 0px;
  flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover {
  position: absolute;
  top: 0;
  right: 0;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ct {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.cl {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.clear-fix {
  *zoom: 1;
}
.clear-fix:before,
.clear-fix:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.page {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #fff;
  overflow: hidden;
  overflow-y: auto;
}
.page /deep/ .comment-list {
  padding: 0.3rem;
}
.page /deep/ .comment-list .comment-item {
  display: flex;
  width: 100%;
}
.page /deep/ .comment-list .comment-item .headimg-box {
  width: 0.7rem;
  height: 0.7rem;
  margin-right: 0.14rem;
  border-radius: 50%;
  overflow: hidden;
}
.page /deep/ .comment-list .comment-item .headimg-box img {
  width: 100%;
  height: 100%;
}
.page /deep/ .comment-list .comment-item .headimg-right {
  width: calc(100% - 0.84rem);
}
.page /deep/ .comment-list .comment-item .headimg-right .title {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.page /deep/ .comment-list .comment-item .headimg-right .title .nickname {
  max-width: 60%;
  font-size: 0.28rem;
  color: #333;
  line-height: 0.4rem;
  word-wrap: break-word;
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.page /deep/ .comment-list .comment-item .headimg-right .title .rate-wrap {
  text-align: right;
}
.page /deep/ .comment-list .comment-item .headimg-right .title .rate-wrap .time {
  margin-right: 0.05rem;
  font-size: 0.24rem;
  color: #999;
  line-height: 0.34rem;
  white-space: nowrap;
}
.page /deep/ .comment-list .comment-item .headimg-right .comment-txt {
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  font-size: 0.28rem;
  color: #333;
  line-height: 0.4rem;
  word-wrap: break-word;
  word-break: break-all;
}
.page /deep/ .comment-list .comment-item .headimg-right .comment-pic-wrap {
  width: 100%;
}
.page /deep/ .comment-list .comment-item .headimg-right .comment-pic-wrap /deep/ .img-list {
  display: flex;
}
.page /deep/ .comment-list .comment-item .headimg-right .comment-pic-wrap /deep/ .img-list img {
  width: 1.9rem;
  height: 1.9rem;
}
.page /deep/ .comment-list .comment-item .headimg-right .comment-pic-wrap /deep/ .img-list img + img {
  margin-left: 0.24rem;
}
.page /deep/ .comment-list .comment-item + .comment-item {
  margin-top: 0.3rem;
}
