.border-none:before {
  display: none;
}
.border-none:after {
  display: none;
}
.flex-fix {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  -webkit-flex-basis: 0px;
  flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover {
  position: absolute;
  top: 0;
  right: 0;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ct {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.cl {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.clear-fix {
  *zoom: 1;
}
.clear-fix:before,
.clear-fix:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.page {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #fff;
  overflow: hidden;
  overflow-y: auto;
  padding: 0.3rem;
}
.page .comment-box {
  width: 100%;
  padding: 0.2rem;
  background-color: #fff;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.05);
}
.page .comment-box .title {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.page .comment-box .title .left {
  flex: 1;
  display: flex;
  align-items: center;
}
.page .comment-box .title .left .headimg-box {
  width: 0.48rem;
  height: 0.48rem;
  margin-right: 0.14rem;
  border-radius: 50%;
  overflow: hidden;
}
.page .comment-box .title .left .headimg-box img {
  width: 100%;
  height: 100%;
}
.page .comment-box .title .nickname {
  max-width: 60%;
  font-size: 0.24rem;
  color: #999;
  line-height: 0.34rem;
  word-wrap: break-word;
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.page .comment-box .title .rate-wrap {
  text-align: right;
}
.page .comment-box .comment-txt {
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  font-size: 0.24rem;
  color: #333;
  line-height: 0.34rem;
  word-wrap: break-word;
  word-break: break-all;
}
.page .comment-box .comment-pic-wrap {
  width: 100%;
}
.page .comment-box .comment-pic-wrap /deep/ .img-list {
  display: flex;
}
.page .comment-box .comment-pic-wrap /deep/ .img-list img {
  width: 1.2rem;
  height: 1.2rem;
}
.page .comment-box .comment-pic-wrap /deep/ .img-list img + img {
  margin-left: 0.24rem;
}
