.border-none[data-v-6c3a5193]:before {
  display: none;
}
.border-none[data-v-6c3a5193]:after {
  display: none;
}
.flex-fix[data-v-6c3a5193] {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  flex: 1;
  -ms-flex-preferred-size: 0px;
      flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar[data-v-6c3a5193]::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover[data-v-6c3a5193] {
  position: absolute;
  top: 0;
  right: 0;
}
.center[data-v-6c3a5193] {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ct[data-v-6c3a5193] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.cl[data-v-6c3a5193] {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.clear-fix[data-v-6c3a5193] {
  *zoom: 1;
}
.clear-fix[data-v-6c3a5193]:before,
.clear-fix[data-v-6c3a5193]:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
#special-topic .title[data-v-6c3a5193] {
  font-size: 0.4rem;
  color: #333;
  height: 0.56rem;
  line-height: 0.56rem;
  font-weight: bold;
}
#special-topic .zjg-max[data-v-6c3a5193] {
  color: var(--main-color);
  padding-left: 0.2rem;
  margin-top: 0.1rem;
  font-size: 0.2rem;
}
#special-topic .cell[data-v-6c3a5193] {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-right: 0.24rem;
}
#special-topic .cell .left .brief[data-v-6c3a5193] {
  font-size: 0.32rem;
  color: #999;
  line-height: 0.44rem;
}
#special-topic .cell .right[data-v-6c3a5193] {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
#special-topic .cell .right .check-btn[data-v-6c3a5193] {
  font-size: 0.28rem;
  color: #999;
  line-height: 0.4rem;
}
#special-topic .cell .icon-arrow-right[data-v-6c3a5193] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 0;
  display: inline-block;
  vertical-align: middle;
  width: 0.12rem;
  height: 0.21rem;
  background-image: url('../../assets/icpn_gengduo@2x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
@media (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 3) {
#special-topic .cell .icon-arrow-right[data-v-6c3a5193] {
    background-image: url('../../assets/icpn_gengduo@3x.png');
}
}
#special-topic .style2[data-v-6c3a5193],
#special-topic .style3[data-v-6c3a5193] {
  padding-bottom: 0.3rem;
}
#special-topic .style2 .title[data-v-6c3a5193],
#special-topic .style2 .cell[data-v-6c3a5193],
#special-topic .style2 ul[data-v-6c3a5193] {
  padding: 0 0.3rem;
}
#special-topic .style1[data-v-6c3a5193] {
  padding: 0 0.3rem 0.4rem;
}
#special-topic .style1 .item-list[data-v-6c3a5193] {
  position: relative;
  overflow: hidden;
}
#special-topic .style1 .item-list .item[data-v-6c3a5193] {
  width: 3.35rem;
  height: 5.15rem;
  margin-top: 0.2rem;
  padding-bottom: 0.15rem;
  float: left;
  position: relative;
  background: #fff;
  -webkit-box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.05);
          box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.05);
  border-radius: 0.08rem;
  overflow: hidden;
  z-index: 0;
}
#special-topic .style1 .item-list .item[data-v-6c3a5193]:nth-child(odd) {
  margin-right: 0.2rem;
}
#special-topic .style1 .item-list .item .img-box[data-v-6c3a5193] {
  width: 100%;
  height: 3.35rem;
  margin-bottom: 0.14rem;
}
#special-topic .style1 .item-list .item .img-box .img[data-v-6c3a5193] {
  width: 100%;
  height: 100%;
}
#special-topic .style1 .item-list .item .sales[data-v-6c3a5193] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 0.1rem;
  padding: 0 0.1rem;
}
#special-topic .style1 .item-list .item .item-name[data-v-6c3a5193] {
  padding: 0 0.2rem;
  height: 0.72rem;
  margin-bottom: 0.12rem;
  font-size: 0.26rem;
  color: rgba(0, 0, 0, 0.87);
  line-height: 0.36rem;
  display: -webkit-box;
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  word-wrap: break-word;
}
#special-topic .style1 .item-list .item .item-name .sourceName[data-v-6c3a5193] {
  position: relative;
  display: inline-block;
  height: 0.36rem;
  line-height: 0.36rem;
  padding: 0 0.15rem;
  border-radius: 0.18rem;
  background-color: var(--main-color);
  color: #ffffff;
  font-size: 0.18rem;
}
#special-topic .style1 .item-list .item .priceWithBtn[data-v-6c3a5193] {
  position: absolute;
  bottom: 4%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
}
#special-topic .style1 .item-list .item .priceWithBtn .item-price[data-v-6c3a5193] {
  padding-left: 0.2rem;
}
#special-topic .style1 .item-list .item .priceWithBtn .item-price .price[data-v-6c3a5193] {
  color: #333;
  line-height: 0.28rem;
  font-weight: bold;
}
#special-topic .style1 .item-list .item .priceWithBtn .item-price .price .sign[data-v-6c3a5193] {
  font-size: 0.24rem;
}
#special-topic .style1 .item-list .item .priceWithBtn .item-price .price .num[data-v-6c3a5193] {
  font-size: 0.32rem;
}
#special-topic .style1 .item-list .item .priceWithBtn .buy-btn[data-v-6c3a5193] {
  /* position: absolute;
						right: 0;
						bottom: 0.2rem; */
  width: 0.9rem;
  height: 0.48rem;
  padding-left: 0.26rem;
  padding-top: 0.08rem;
  font-size: 0.24rem;
  color: #fff;
  line-height: 0.36rem;
  font-weight: 500;
  white-space: nowrap;
  background-color: var(--main-color);
  border-radius: 2rem 0px 0px 2rem;
  z-index: 2;
}
#special-topic .style2[data-v-6c3a5193] {
  position: relative;
}
#special-topic .style2 .theme-img-box[data-v-6c3a5193] {
  width: 100%;
  height: 4.2rem;
  margin-bottom: 0.18rem;
}
#special-topic .style2 .theme-img-box .img[data-v-6c3a5193] {
  width: 100%;
  height: 100%;
}
#special-topic .style2 ul[data-v-6c3a5193] {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  overflow-x: auto;
  margin-bottom: 0.4rem;
  -webkit-overflow-scrolling: touch;
}
#special-topic .style2 ul li[data-v-6c3a5193] {
  display: inline-block;
  width: 2.4rem;
  padding-bottom: 0.1rem;
  position: relative;
  background: #fff;
  border-radius: 4px;
  overflow: hidden;
  z-index: 0;
}
#special-topic .style2 ul li .img-box[data-v-6c3a5193] {
  width: 2.4rem;
  height: 2.4rem;
}
#special-topic .style2 ul li .img-box .img[data-v-6c3a5193] {
  width: 100%;
  height: 100%;
}
#special-topic .style2 ul li .sales[data-v-6c3a5193] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-top: 0.1rem;
  padding-right: 0.1rem;
}
#special-topic .style2 ul li .item-name[data-v-6c3a5193] {
  width: 2.24rem;
  height: 0.32rem;
  margin: 0.1rem 0 0 0;
  padding: 0 0.08rem;
  font-size: 0.22rem;
  color: rgba(0, 0, 0, 0.87);
  line-height: 0.32rem;
  word-wrap: break-word;
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#special-topic .style2 ul li .item-name .sourceName[data-v-6c3a5193] {
  position: relative;
  display: inline-block;
  height: 0.32rem;
  line-height: 0.32rem;
  padding: 0 0.15rem;
  border-radius: 0.16rem;
  background-color: var(--main-color);
  color: #ffffff;
  font-size: 0.18rem;
}
#special-topic .style2 ul li .item-price[data-v-6c3a5193] {
  margin: 0.1rem 0 0 0;
  padding: 0 0.08rem;
  font-size: 0.24rem;
  color: #333;
  line-height: 0.28rem;
  font-weight: bold;
}
#special-topic .style2 ul li .no-padding[data-v-6c3a5193] {
  padding-left: 0;
}
#special-topic .style2 ul li + li[data-v-6c3a5193] {
  margin-left: 0.1rem;
}
#special-topic .style2 .check-more[data-v-6c3a5193] {
  width: 100%;
}
#special-topic .style2 .check-more .check-btn[data-v-6c3a5193] {
  margin-right: 0.08rem;
  font-size: 0.28rem;
  color: #999;
  line-height: 0.4rem;
}
#special-topic .style2 .check-more .icon-arrow-right[data-v-6c3a5193] {
  display: inline-block;
  vertical-align: middle;
  width: 0.12rem;
  height: 0.22rem;
  background-image: url('../../assets/icpn_gengduo@2x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
@media (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 3) {
#special-topic .style2 .check-more .icon-arrow-right[data-v-6c3a5193] {
    background-image: url('../../assets/icpn_gengduo@3x.png');
}
}
#special-topic .style2[data-v-6c3a5193]:after {
  content: "";
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  bottom: 0.1rem;
  display: block;
  width: calc(100% - 0.6rem);
  height: 1px;
  background-color: #e1e1e1;
}
#special-topic .style3 .theme-img-box[data-v-6c3a5193] {
  padding: 0 0.3rem;
  height: 1.88rem;
}
#special-topic .style3 .theme-img-box .img[data-v-6c3a5193] {
  width: 100%;
  height: 100%;
}
#special-topic .style3 ul[data-v-6c3a5193] {
  width: 100%;
  height: 4.3rem;
  padding: 0.18rem 0.3rem 0 0.18rem;
  overflow: hidden;
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}
#special-topic .style3 ul .zjg-item[data-v-6c3a5193] {
  height: 4rem;
}
#special-topic .style3 ul li[data-v-6c3a5193] {
  display: inline-block;
  width: 2.4rem;
  height: 3.34rem;
  padding-bottom: 0.1rem;
  position: relative;
  background: #fff;
  border-radius: 4px;
  z-index: 0;
  overflow: hidden;
}
#special-topic .style3 ul li + li[data-v-6c3a5193] {
  margin-left: 0.16rem;
}
#special-topic .style3 ul li .img-box[data-v-6c3a5193] {
  width: 2.4rem;
  height: 2.4rem;
}
#special-topic .style3 ul li .img-box .img[data-v-6c3a5193] {
  width: 100%;
  height: 100%;
}
#special-topic .style3 ul li .sales[data-v-6c3a5193] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 0.1rem;
  padding: 0 0.1rem;
}
#special-topic .style3 ul li .item-name[data-v-6c3a5193] {
  width: 2.24rem;
  height: 0.34rem;
  padding: 0 0.08rem;
  margin: 0.08rem 0 0.12rem 0;
  font-size: 0.24rem;
  color: rgba(0, 0, 0, 0.87);
  line-height: 0.34rem;
  display: -webkit-box;
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  word-wrap: break-word;
}
#special-topic .style3 ul li .item-name .sourceName[data-v-6c3a5193] {
  position: relative;
  display: inline-block;
  height: 0.34rem;
  line-height: 0.34rem;
  padding: 0 0.15rem;
  border-radius: 0.17rem;
  background-color: var(--main-color);
  color: #ffffff;
  font-size: 0.18rem;
}
#special-topic .style3 ul li .item-price[data-v-6c3a5193] {
  padding: 0 0.08rem;
  margin: 0;
  font-size: 0.24rem;
  color: #333;
  line-height: 0.28rem;
  font-weight: bold;
}
#special-topic .style3 ul li .no-padding[data-v-6c3a5193] {
  padding-left: 0;
}
