.border-none:before {
  display: none;
}
.border-none:after {
  display: none;
}
.flex-fix {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  -webkit-flex-basis: 0px;
  flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover {
  position: absolute;
  top: 0;
  right: 0;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ct {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.cl {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.clear-fix {
  *zoom: 1;
}
.clear-fix:before,
.clear-fix:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.item-list {
  position: relative;
  overflow: hidden;
}
@media screen and (max-width: 374px) {
  .item-list .had-stage {
    height: 5.2rem;
  }
}
@media screen and (min-width: 321px) {
  .item-list .had-stage {
    height: 5.2rem;
  }
}
.item-list .no-stage {
  height: 4.8rem;
}
.item-list .item {
  width: 47.5%;
  margin-top: 0.3rem;
  padding-bottom: 0.15rem;
  float: left;
  position: relative;
  background: #fff;
  box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.05);
  border-radius: 0.08rem;
  overflow: hidden;
  z-index: 0;
}
.item-list .item .img-box {
  width: 100%;
  height: 3.3rem;
  margin-bottom: 0.14rem;
  position: relative;
}
.item-list .item .img-box img {
  width: 100%;
  height: 100%;
}
.item-list .item .sales {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.1rem;
  padding: 0 0.1rem;
}
.item-list .item .item-name {
  height: 0.7rem;
  padding: 0 0.2rem;
  margin-bottom: 0.12rem;
  font-size: 0.26rem;
  color: rgba(0, 0, 0, 0.87);
  line-height: 0.36rem;
  display: -webkit-box;
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  word-wrap: break-word;
}
.item-list .item .item-name .sourceName {
  position: relative;
  top: -0.02rem;
  display: inline-block;
  height: 0.3rem;
  line-height: 0.36rem;
  padding: 0 0.15rem;
  border-radius: 0.2rem;
  background-color: var(--main-color);
  color: #ffffff;
  font-size: 0.18rem;
}
.item-list .item .priceWithBtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.2rem;
}
.item-list .item .priceWithBtn .item-price {
  padding-left: 0.2rem;
  /* position: absolute;
				bottom: 0.26rem; */
}
.item-list .item .priceWithBtn .item-price .price {
  color: #FA5212;
  line-height: 0.28rem;
  font-size: 0.28rem;
  font-weight: bold;
}
.item-list .item .priceWithBtn .item-price .price .sign {
  font-size: 0.24rem;
}
.item-list .item .priceWithBtn .item-price .price .num {
  font-size: 0.32rem;
}
.item-list .item .priceWithBtn .buy-btn {
  position: absolute;
  right: 0;
  bottom: 0.17rem;
  width: 0.9rem;
  height: 0.48rem;
  padding-left: 0.26rem;
  padding-top: 0.08rem;
  font-size: 0.24rem;
  color: #fff;
  line-height: 0.34rem;
  font-weight: 500;
  white-space: nowrap;
  background-color: var(--main-color);
  border-radius: 2rem 0px 0px 2rem;
  z-index: 2;
}
.item-list .item .lusse {
  padding: 0 0.2rem;
  color: #000;
}
.item-list .item .lusse .plans {
  color: #fe5d1c;
}
.item-list .item .lusse .money {
  font-size: 0.4rem;
}
.item-list .item .lusse .original-price {
  margin-top: 0.15rem;
  color: #999999;
}
.item-list .item .benefit-wrap {
  position: absolute;
  top: 0.2rem;
  left: 0.2rem;
  display: flex;
  align-items: center;
  height: 0.36rem;
  border: 1px solid var(--main-color);
  border-radius: 0.18rem;
  z-index: 2;
}
.item-list .item .benefit-wrap .benefit {
  font-size: 0.22rem;
  color: var(--main-color);
  line-height: 0.32rem;
  white-space: nowrap;
}
.item-list .item:nth-child(odd) {
  margin-right: 4%;
}
