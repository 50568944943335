.border-none[data-v-874733d0]:before {
  display: none;
}
.border-none[data-v-874733d0]:after {
  display: none;
}
.flex-fix[data-v-874733d0] {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  flex: 1;
  -ms-flex-preferred-size: 0px;
      flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar[data-v-874733d0]::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover[data-v-874733d0] {
  position: absolute;
  top: 0;
  right: 0;
}
.center[data-v-874733d0] {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ct[data-v-874733d0] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.cl[data-v-874733d0] {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.clear-fix[data-v-874733d0] {
  *zoom: 1;
}
.clear-fix[data-v-874733d0]:before,
.clear-fix[data-v-874733d0]:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
#new-category[data-v-874733d0] {
  padding-bottom: 0.2rem;
}
#new-category .four[data-v-874733d0] {
  margin: 0 0.3rem;
}
#new-category .four .category-item[data-v-874733d0] {
  width: 25%;
  margin-bottom: 0.3rem;
}
#new-category .four .category-item .img-box[data-v-874733d0] {
  height: 1rem;
  margin-bottom: 0.2rem;
}
#new-category .four .category-item .txt-box[data-v-874733d0] {
  height: 0.36rem;
}
#new-category .four .category-icon[data-v-874733d0] {
  width: 1rem;
  height: 1rem;
}
#new-category .four .category-txt[data-v-874733d0] {
  font-size: 0.26rem;
  color: #666;
  line-height: 0.36rem;
}
#new-category .five[data-v-874733d0] {
  margin: 0 0.4rem;
}
#new-category .five .category-item[data-v-874733d0] {
  width: 20%;
  margin-bottom: 0.11rem;
}
#new-category .five .category-item .icon-style[data-v-874733d0] {
  padding: 0.25rem;
}
#new-category .five .category-item .txt-box[data-v-874733d0] {
  color: #333333;
  font-size: 0.2rem;
}
#new-category .category-list[data-v-874733d0] {
  position: relative;
  overflow: hidden;
}
#new-category .category-list .category-item[data-v-874733d0] {
  float: left;
  position: relative;
  z-index: 0;
}
#new-category .border-four[data-v-874733d0] {
  border-radius: 0.2rem;
  background-color: #ffffff;
  padding-top: 0.3rem;
  box-shadow: 0 0 5px #e0e0e0;
  -webkit-box-shadow: 0 0 5px #e0e0e0;
}
#new-category .border-five[data-v-874733d0] {
  border-radius: 0.2rem;
  background-color: #ffffff;
  padding: 0.1rem 0 0.3rem 0;
  box-shadow: 0 0 5px #e0e0e0;
  -webkit-box-shadow: 0 0 5px #e0e0e0;
}
