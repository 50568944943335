.border-none:before {
  display: none;
}
.border-none:after {
  display: none;
}
.flex-fix {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  -webkit-flex-basis: 0px;
  flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover {
  position: absolute;
  top: 0;
  right: 0;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ct {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.cl {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.clear-fix {
  *zoom: 1;
}
.clear-fix:before,
.clear-fix:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
#announcement .announcement-cell {
  position: relative;
  display: flex;
  align-items: center;
  height: 0.76rem;
  padding-left: 1.76rem;
}
#announcement .announcement-cell .icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0.3rem;
  width: 1.16rem;
  height: 0.38rem;
  background-color: var(--main-color);
  z-index: 1;
}
#announcement .announcement-content {
  width: 100%;
  font-size: 0.26rem;
  color: #666;
  line-height: 0.32rem;
  letter-spacing: 0.01rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#announcement /deep/ .yd-rollnotice {
  background-color: transparent;
}
