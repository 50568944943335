.border-none:before {
  display: none;
}
.border-none:after {
  display: none;
}
.flex-fix {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  -webkit-flex-basis: 0px;
  flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover {
  position: absolute;
  top: 0;
  right: 0;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ct {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.cl {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.clear-fix {
  *zoom: 1;
}
.clear-fix:before,
.clear-fix:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.page {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #f7f7f7;
  overflow: hidden;
  overflow-y: auto;
}
.page .group-item-wrap {
  padding: 0.48rem 0.3rem 0.09rem;
  background-color: #fff;
}
.page .group-item-wrap /deep/ .group-play {
  border-top: 0;
}
.page .item-info {
  position: relative;
  width: 100%;
  height: 2.2rem;
  padding-top: 0.24rem;
  padding-left: 2.4rem;
  margin-bottom: 0.31rem;
  background-color: #fff;
  box-shadow: 0px 0.06rem 0.4rem 0px rgba(0, 0, 0, 0.05);
  border-radius: 0.08rem;
  overflow: hidden;
  z-index: 1;
}
.page .item-info .img-box {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 2.2rem;
  height: 2.2rem;
  background-color: #faf8fb;
  z-index: 2;
}
.page .item-info .img-box img {
  width: 100%;
  height: 100%;
}
.page .item-info .item-name {
  max-width: 100%;
  font-size: 0.28rem;
  color: rgba(0, 0, 0, 0.87);
  line-height: 0.4rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.page .item-info .item-intro {
  display: -webkit-box;
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  word-wrap: break-word;
  font-size: 0.24rem;
  color: #999;
  line-height: 0.34rem;
}
.page .item-info .feature-list {
  position: relative;
  margin-bottom: 0.14rem;
  overflow: hidden;
}
.page .item-info .feature-list li {
  float: left;
  position: relative;
  padding: 0.02rem 0.12rem;
  margin-top: 0.12rem;
  font-size: 0.22rem;
  color: var(--main-color);
  line-height: 0.32rem;
  text-align: center;
  white-space: nowrap;
  border: 1px solid var(--main-color);
  border-radius: 0.18rem;
  overflow: hidden;
  z-index: 0;
}
.page .item-info .feature-list li + li {
  margin-left: 0.12rem;
}
.page .item-info .item-price {
  position: absolute;
  left: 2.4rem;
  bottom: 0.2rem;
}
.page .item-info .item-price .txt {
  font-size: 0.28rem;
  color: var(--main-color);
  line-height: 0.4rem;
}
.page .item-info .item-price .price {
  color: #333;
  line-height: 0.28rem;
  font-weight: bold;
}
.page .item-info .item-price .price .sign {
  font-size: 0.24rem;
}
.page .item-info .item-price .price .num {
  font-size: 0.32rem;
}
.page .item-info .item-price .old-price {
  display: inline-block;
  margin-left: 0.08rem;
  font-size: 0.16rem;
  color: rgba(0, 0, 0, 0.38);
  line-height: 0.2rem;
  font-weight: bold;
  text-decoration: line-through;
}
.page .item-info .item-num {
  position: absolute;
  right: 0.2rem;
  bottom: 0.2rem;
  font-size: 0.24rem;
  color: #333;
  line-height: 0.28rem;
  font-weight: bolder;
}
.page .group-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.34rem 0.3rem 3.52rem;
}
.page .group-info .title {
  font-size: 0.28rem;
  color: #666;
  line-height: 0.4rem;
  text-align: center;
}
.page .group-info .title .num {
  margin: 0 0.08rem;
  color: var(--main-color);
  font-weight: bolder;
}
.page .group-info .group-list-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 2.4rem;
  padding: 0 0.24rem 0.24rem 0.66rem;
  background-image: url('../../../assets/img_tuangou_bg_shixiao@2x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
@media (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 3) {
  .page .group-info .group-list-wrap {
    background-image: url('../../../assets/img_tuangou_bg_shixiao@3x.png');
  }
}
.page .group-info .group-list-wrap .group-list {
  position: relative;
  padding-top: 0.72rem;
  margin-bottom: 0.14rem;
}
.page .group-info .group-list-wrap .group-list li {
  float: left;
  position: relative;
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  z-index: 0;
}
.page .group-info .group-list-wrap .group-list li img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
}
.page .group-info .group-list-wrap .group-list li .head-badge {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -0.1rem;
  display: inline-block;
  width: 0.56rem;
  height: 0.26rem;
  padding-top: 0.02rem;
  padding-left: 0.02rem;
  font-size: 0.2rem;
  color: #fff;
  line-height: 0.26rem;
  text-align: center;
  background-color: var(--main-color);
  border-radius: 0.13rem;
  overflow: hidden;
  z-index: 3;
}
.page .group-info .group-list-wrap .group-list li.active {
  border: 1px solid var(--main-color);
  margin-right: 0.4rem;
  margin-bottom: 0.32rem;
}
.page .group-info .group-list-wrap .group-list li + li {
  margin-right: 0.4rem;
  margin-bottom: 0.32rem;
}
.page .group-info .group-list-wrap .countdown-wrap {
  display: flex;
  align-items: center;
}
.page .group-info .group-list-wrap .countdown-wrap .gray-txt {
  font-size: 0.22rem;
  color: #999;
  line-height: 0.32rem;
}
.page .group-info .group-list-wrap .countdown-wrap .time {
  margin: 0 0.08rem;
  font-size: 0.28rem;
  color: var(--main-color);
  line-height: 0.4rem;
  font-weight: bold;
}
.page .buy-btn {
  width: 6rem;
  height: 0.88rem;
  margin: 0.4rem auto;
  font-size: 0.32rem;
  color: #fff;
  line-height: 0.88rem;
  text-align: center;
  font-weight: bold;
  white-space: nowrap;
  background-color: var(--main-color);
  border-radius: 0.44rem;
  overflow: hidden;
}
.page .buy-btn:active {
  opacity: 0.7;
}
