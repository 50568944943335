.border-none:before {
  display: none;
}
.border-none:after {
  display: none;
}
.flex-fix {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  -webkit-flex-basis: 0px;
  flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover {
  position: absolute;
  top: 0;
  right: 0;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ct {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.cl {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.clear-fix {
  *zoom: 1;
}
.clear-fix:before,
.clear-fix:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
#seconds-kill {
  padding: 0.4rem 0.3rem 0;
}
#seconds-kill .title {
  font-size: 0.4rem;
  color: #333;
  line-height: 0.56rem;
  font-weight: bold;
}
#seconds-kill .cell {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 0.24rem;
}
#seconds-kill .cell .left .brief {
  font-size: 0.32rem;
  color: #999;
  line-height: 0.44rem;
}
#seconds-kill .cell .right {
  position: relative;
  display: flex;
  justify-content: flex-end;
}
#seconds-kill .cell .right .check-btn {
  font-size: 0.28rem;
  color: #999;
  line-height: 0.4rem;
}
#seconds-kill .cell .icon-arrow-right {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  display: inline-block;
  width: 0.12rem;
  height: 0.21rem;
  background-image: url('../../assets/icpn_gengduo@2x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
@media (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 3) {
  #seconds-kill .cell .icon-arrow-right {
    background-image: url('../../assets/icpn_gengduo@3x.png');
  }
}
#seconds-kill .kill-list {
  position: relative;
  overflow: hidden;
}
#seconds-kill .kill-list .kill-item {
  width: 3.3rem;
  height: 4.6rem;
  margin-top: 0.2rem;
  float: left;
  position: relative;
  background: #fff;
  box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.05);
  border-radius: 0.08rem;
  overflow: hidden;
  z-index: 0;
}
#seconds-kill .kill-list .kill-item .img-box {
  width: 100%;
  height: 2.6rem;
  margin-bottom: 0.14rem;
}
#seconds-kill .kill-list .kill-item .img-box img {
  width: 100%;
  height: 100%;
}
#seconds-kill .kill-list .kill-item .item-name {
  padding: 0 0.2rem;
  margin-bottom: 0.12rem;
  font-size: 0.26rem;
  color: rgba(0, 0, 0, 0.87);
  line-height: 0.36rem;
  display: -webkit-box;
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  word-wrap: break-word;
}
#seconds-kill .kill-list .kill-item .item-name .source-name {
  position: relative;
  top: -0.02rem;
  display: inline-block;
  height: 0.3rem;
  line-height: 0.355rem;
  padding: 0 0.15rem;
  border-radius: 0.2rem;
  background-color: var(--main-color);
  color: #ffffff;
  font-size: 0.18rem;
}
#seconds-kill .kill-list .kill-item .item-price {
  padding-left: 0.2rem;
}
#seconds-kill .kill-list .kill-item .item-price .seconds-price {
  color: #333;
  line-height: 0.28rem;
  font-weight: bold;
}
#seconds-kill .kill-list .kill-item .item-price .seconds-price .sign {
  font-size: 0.24rem;
}
#seconds-kill .kill-list .kill-item .item-price .seconds-price .num {
  font-size: 0.32rem;
}
#seconds-kill .kill-list .kill-item .item-price .old-price {
  display: inline-block;
  margin-left: 0.08rem;
  font-size: 0.16rem;
  color: rgba(0, 0, 0, 0.38);
  line-height: 0.2rem;
  font-weight: bold;
  text-decoration: line-through;
}
#seconds-kill .kill-list .kill-item .kill-btn {
  position: absolute;
  right: 0;
  bottom: 0.2rem;
  width: 0.9rem;
  height: 0.48rem;
  padding-left: 0.26rem;
  padding-top: 0.08rem;
  font-size: 0.24rem;
  color: #fff;
  line-height: 0.34rem;
  font-weight: 500;
  white-space: nowrap;
  background-color: var(--main-color);
  border-radius: 2rem 0px 0px 2rem;
  z-index: 2;
}
#seconds-kill .kill-list .kill-item .countdown-wrap {
  position: absolute;
  top: 0.2rem;
  left: 0.2rem;
  display: flex;
  align-items: center;
  width: 2.4rem;
  height: 0.42rem;
  padding-left: 1.08rem;
  background-image: url('../../assets/img_home_miaosha_time@2x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  z-index: 2;
}
@media (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 3) {
  #seconds-kill .kill-list .kill-item .countdown-wrap {
    background-image: url('../../assets/img_home_miaosha_time@3x.png');
  }
}
#seconds-kill .kill-list .kill-item .countdown-wrap .session {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0.2rem;
  display: inline-block;
  font-size: 0.24rem;
  color: #fff;
  line-height: 0.28rem;
  font-weight: bold;
  white-space: nowrap;
  z-index: 3;
}
#seconds-kill .kill-list .kill-item .countdown-wrap .time {
  font-size: 0.24rem;
  color: #FE552E;
  font-weight: bold;
  text-align: center;
}
#seconds-kill .kill-list .kill-item .countdown-wrap /deep/ span {
  text-align: center;
}
#seconds-kill .kill-list .kill-item:nth-child(odd) {
  margin-right: 0.3rem;
}
