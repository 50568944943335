.border-none[data-v-6c2b77c8]:before {
  display: none;
}
.border-none[data-v-6c2b77c8]:after {
  display: none;
}
.flex-fix[data-v-6c2b77c8] {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  flex: 1;
  -ms-flex-preferred-size: 0px;
      flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar[data-v-6c2b77c8]::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover[data-v-6c2b77c8] {
  position: absolute;
  top: 0;
  right: 0;
}
.center[data-v-6c2b77c8] {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ct[data-v-6c2b77c8] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.cl[data-v-6c2b77c8] {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.clear-fix[data-v-6c2b77c8] {
  *zoom: 1;
}
.clear-fix[data-v-6c2b77c8]:before,
.clear-fix[data-v-6c2b77c8]:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.page[data-v-6c2b77c8] {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #fff;
  overflow: hidden;
  overflow-y: auto;
  padding: 0.3rem;
}
.page .comment-box[data-v-6c2b77c8] {
  width: 100%;
  padding: 0.2rem;
  background-color: #fff;
  -webkit-box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.05);
          box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.05);
}
.page .comment-box .title[data-v-6c2b77c8] {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.page .comment-box .title .left[data-v-6c2b77c8] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.page .comment-box .title .left .headimg-box[data-v-6c2b77c8] {
  width: 0.48rem;
  height: 0.48rem;
  margin-right: 0.14rem;
  border-radius: 50%;
  overflow: hidden;
}
.page .comment-box .title .left .headimg-box img[data-v-6c2b77c8] {
  width: 100%;
  height: 100%;
}
.page .comment-box .title .nickname[data-v-6c2b77c8] {
  max-width: 60%;
  font-size: 0.24rem;
  color: #999;
  line-height: 0.34rem;
  word-wrap: break-word;
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.page .comment-box .title .rate-wrap[data-v-6c2b77c8] {
  text-align: right;
}
.page .comment-box .comment-txt[data-v-6c2b77c8] {
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  font-size: 0.24rem;
  color: #333;
  line-height: 0.34rem;
  word-wrap: break-word;
  word-break: break-all;
}
.page .comment-box .comment-pic-wrap[data-v-6c2b77c8] {
  width: 100%;
}
.page .comment-box .comment-pic-wrap[data-v-6c2b77c8] .img-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.page .comment-box .comment-pic-wrap[data-v-6c2b77c8] .img-list img {
  width: 1.2rem;
  height: 1.2rem;
}
.page .comment-box .comment-pic-wrap[data-v-6c2b77c8] .img-list img + img {
  margin-left: 0.24rem;
}
