.page {
  padding-bottom: 0.98rem;
  position: absolute;
  width: 100%;
}
.page .zhIndexIconKf {
  position: fixed;
  z-index: 99;
  width: 70px;
  height: 27px;
  right: 0;
  margin-top: 150px;
}
.page .activityicon {
  position: fixed;
  z-index: 99;
  width: 70px;
  height: 27px;
  right: 0;
  margin-top: 70px;
}
.page .indexOrdericon {
  position: fixed;
  z-index: 99;
  width: 70px;
  height: 75px;
  right: 0;
  margin-top: 470px;
}
.page .to-shop {
  width: 100%;
  height: 0.8rem;
  background: yellow;
  text-align: center;
  line-height: 0.8rem;
}
.page .custom-img {
  display: block;
  width: 100%;
  margin: 0.1rem 0;
  padding: 0 0.3rem;
}
.mt20 {
  margin-top: 0.4rem;
}
.zh_zzc {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999999;
  background: #ffffff;
  opacity: 0;
}
.auto_info {
  text-align: center;
  margin: 0.2rem auto;
  text-decoration: underline;
}
