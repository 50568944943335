.border-none[data-v-7f221e2c]:before {
  display: none;
}
.border-none[data-v-7f221e2c]:after {
  display: none;
}
.flex-fix[data-v-7f221e2c] {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  flex: 1;
  -ms-flex-preferred-size: 0px;
      flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar[data-v-7f221e2c]::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover[data-v-7f221e2c] {
  position: absolute;
  top: 0;
  right: 0;
}
.center[data-v-7f221e2c] {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ct[data-v-7f221e2c] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.cl[data-v-7f221e2c] {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.clear-fix[data-v-7f221e2c] {
  *zoom: 1;
}
.clear-fix[data-v-7f221e2c]:before,
.clear-fix[data-v-7f221e2c]:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.page[data-v-7f221e2c] {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #FAFAFA;
  overflow: hidden;
  overflow-y: auto;
}
.page .info-box[data-v-7f221e2c] {
  padding: 0 0.3rem;
  background-color: #fff;
}
.page .info-box .title[data-v-7f221e2c] {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  height: 1rem;
  border-bottom: 1px solid #F1F1F1;
}
.page .info-box .title .txt[data-v-7f221e2c] {
  font-size: 0.28rem;
  color: #999;
  line-height: 0.4rem;
}
.page .info-box .title .time[data-v-7f221e2c] {
  font-size: 0.24rem;
  color: #999;
  line-height: 0.34rem;
  text-align: right;
}
.page .info-box .content[data-v-7f221e2c] {
  padding: 0.2rem 0;
  font-size: 0.28rem;
  color: #333;
  line-height: 0.5rem;
  font-weight: bold;
  word-wrap: break-word;
  word-break: break-all;
}
.page .info-box .content .no-title[data-v-7f221e2c] {
  text-align: center;
  font-size: 0.32rem;
  color: #333;
}
